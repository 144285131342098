import React, { useReducer, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom"; // Import useLocation
import routesConfig from "./data/routesConfig.json"; // Import routesConfig

import ForegroundLayer from "./ForegroundLayer";
import MiddleLayer from "./MiddleLayer";
import PageLayout from "./PageLayout";
import gsap from "gsap";

import "./App.css";

const actionTypes = {
  TOGGLE_BACK_LAYER: "TOGGLE_BACK_LAYER",
  ANIMATE_MIDDLE_LAYER: "ANIMATE_MIDDLE_LAYER",
  SET_SPHERE_ANIMATION_STATE: "SET_SPHERE_ANIMATION_STATE",
  SET_TECH_ICON_ANIMATION: "SET_TECH_ICON_ANIMATION",
  SET_CURRENT_TECH_ICONS: "SET_CURRENT_TECH_ICONS", // New action type
  SET_LIGHT_COLOR: "SET_LIGHT_COLOR", // New action type
};

const initialState = {
  showBackLayer: false,
  animateMiddleLayer: false,
  sphereAnimationState: "idle",
  techIconAnimation: { action: "animateOut", section: null },
  currentTechIcons: [], // New state property
  lightColor: { r: 1, g: 1, b: 1 }, // New state property
};
function reducer(state, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_BACK_LAYER:
      return { ...state, showBackLayer: action.payload };
    case actionTypes.ANIMATE_MIDDLE_LAYER:
      return { ...state, animateMiddleLayer: action.payload };
    case actionTypes.SET_SPHERE_ANIMATION_STATE:
      return { ...state, sphereAnimationState: action.payload };
    case actionTypes.SET_TECH_ICON_ANIMATION:
      return { ...state, techIconAnimation: action.payload };
    case actionTypes.SET_CURRENT_TECH_ICONS: // Handle new action type
      return { ...state, currentTechIcons: action.payload };
    case actionTypes.SET_LIGHT_COLOR: // Handle new action type
      return { ...state, lightColor: action.payload };
    default:
      return state;
  }
}
function colorToRGB(colorInput) {
  // Create a temporary element to apply the color to
  const tempElement = document.createElement("div");
  tempElement.style.color = colorInput;
  document.body.appendChild(tempElement);

  // Get the computed color style
  const style = getComputedStyle(tempElement);
  let color = style.color;
  document.body.removeChild(tempElement);

  // Convert computed color (rgb or rgba) to RGB object
  const match = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*\d+)?\)$/);
  if (match) {
    return {
      r: parseInt(match[1], 10),
      g: parseInt(match[2], 10),
      b: parseInt(match[3], 10),
    };
  }

  // If input was not a recognized CSS color name or an error occurred, return black or a default
  return { r: 1, g: 1, b: 1 };
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const pageLayoutRef = useRef();
  const location = useLocation(); // Use the useLocation hook
  const navigate = useNavigate(); // Ensure you have this if you're using navigate in the function
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  // Check if the current path matches any path in routesConfig
  // Simplify the check for page layout visibility
  const isPageLayoutRoute = routesConfig.some(
    (route) => location.pathname === route.path
  );

  const handleNavigationButtonClick = () => {
    gsap.to(".foregroundLayerContainer", { autoAlpha: 0, duration: 1 });
    gsap.to(".middle-layer", { autoAlpha: 0, duration: 0.5 });
    dispatch({ type: actionTypes.ANIMATE_MIDDLE_LAYER, payload: true });
    dispatch({ type: actionTypes.TOGGLE_BACK_LAYER, payload: true }); // Ensure this is called
    console.log("Navigation button clicked, showBackLayer should now be true");
  };

  const handleCloseButtonClick = () => {
    // Fade out the back layer
    gsap.to(pageLayoutRef.current, {
      autoAlpha: 0,
      duration: 0.5, // Ensure this matches the duration of the fade-in animations below
      onComplete: () => {
        dispatch({ type: actionTypes.TOGGLE_BACK_LAYER, payload: false });
        // Fade in the foreground and middle layers after the back layer has faded out
        gsap.to(".foregroundLayerContainer", { autoAlpha: 1, duration: 0.5 });
        gsap.to(".middle-layer", { autoAlpha: 1, duration: 0.5 });
        navigate("/");
      },
    });
  };

  useEffect(() => {
    // Simulate a loading process, e.g., fetching data or waiting for something
    // In a real app, replace this with your actual loading logic
    const timer = setTimeout(() => {
      setIsContentLoaded(true);
    }, 2000); // Adjust time based on your needs

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (pageLayoutRef.current) {
      gsap.to(pageLayoutRef.current, {
        autoAlpha: state.showBackLayer ? 1 : 0,
        duration: 4,
        delay: 0,
      });
    } else {
      console.log("pageLayoutRef.current is not defined");
    }
  }, [state.showBackLayer]);
  useEffect(() => {
    // Assuming routesConfig[0] contains a lightColor property
    // Example: { path: '/', name: 'Home', lightColor: { r: 0.5, g: 0.5, b: 0.5 } }
    const initialLightColor = routesConfig[0].lightColor;
    if (initialLightColor) {
      dispatch({
        type: actionTypes.SET_LIGHT_COLOR,
        payload: colorToRGB(initialLightColor),
      });
    }
  }, [dispatch]);

  useEffect(() => {
    gsap.to(".middle-layer", {
      autoAlpha: state.animateMiddleLayer ? 0 : 1,
      duration: 1,
    });
  }, [state.animateMiddleLayer]);

  // useEffect(() => {
  //   if (state.showBackLayer) {
  //     // Fade in PageLayout
  //     gsap.to(pageLayoutRef.current, { opacity: 1, duration: 3 });
  //   } else {
  //     // Fade out PageLayout
  //     gsap.to(pageLayoutRef.current, { opacity: 0, duration: 3 });
  //   }
  // }, [state.showBackLayer]);

  return (
    <>
      <div
        className={`fullscreen-blackout ${isContentLoaded ? "fade-out" : ""}`}
      ></div>

      <div className='logo'>
        {" "}
        <Link to='/'>
          <svg>
            <g>
              <path
                d='M78.09,69.55c-2.22,1.75-4.66,3.22-7.29,4.35c-3.96,1.71-8.33,2.66-12.91,2.66c-7.36,0-14.17-2.45-19.64-6.58
c-2.17-1.63-4.12-3.53-5.82-5.64c-1.8-2.24-3.32-4.71-4.48-7.38c-1.76-4.01-2.75-8.44-2.75-13.09c0-4.58,0.95-8.95,2.66-12.91
c1.13-2.63,2.6-5.07,4.35-7.29c1.6-2.02,3.42-3.85,5.45-5.45c5.57-4.4,12.59-7.04,20.22-7.04c4.65,0,9.08,0.98,13.09,2.75
c2.66,1.17,5.14,2.68,7.38,4.48l5.68-5.68c-2.18-1.83-4.55-3.44-7.07-4.79C71.28,4.92,64.79,3.19,57.9,3.19
c-9.84,0-18.87,3.51-25.91,9.34C30,14.18,28.18,16,26.53,17.99c-1.78,2.15-3.34,4.5-4.65,6.99c-2.98,5.65-4.67,12.08-4.67,18.9
c0,6.89,1.73,13.38,4.76,19.08c1.35,2.53,2.96,4.9,4.79,7.07c1.74,2.07,3.69,3.96,5.81,5.65c6.96,5.55,15.76,8.88,25.33,8.88
c6.82,0,13.25-1.69,18.9-4.67c2.49-1.31,4.84-2.87,6.99-4.65c1.98-1.64,3.81-3.47,5.45-5.45l-5.69-5.69
C81.95,66.12,80.12,67.95,78.09,69.55z'
                fill='#ffffff'
              />
              <path
                d='M100.18,8.07l-5.67,5.67c6.76,8.2,10.83,18.71,10.83,30.15c0,11.7-4.27,22.42-11.32,30.7l5.67,5.67
c8.49-9.74,13.65-22.46,13.65-36.37C113.35,30.24,108.38,17.74,100.18,8.07z'
                fill='#ffffff'
              />
              <path
                d='M10.45,43.88c0-8.7,2.36-16.85,6.47-23.87c1.41-2.41,3.03-4.69,4.83-6.81c1.67-1.96,3.49-3.78,5.45-5.45
l-5.67-5.67c-1.94,1.69-3.76,3.51-5.45,5.45c-1.82,2.09-3.48,4.32-4.98,6.66c-5.47,8.59-8.65,18.77-8.65,29.69
c0,10.98,3.22,21.22,8.76,29.85c1.53,2.38,3.23,4.64,5.1,6.76l5.67-5.67c-1.85-2.14-3.51-4.45-4.96-6.9
C12.85,60.86,10.45,52.65,10.45,43.88z'
                fill='#ffffff'
              />
              <path
                d='M67.41,58.87l5.76,5.76c2.08-1.53,3.92-3.37,5.45-5.45c3.17-4.28,5.05-9.57,5.05-15.3
c0-5.46-1.71-10.52-4.61-14.69c-1.55-2.23-3.45-4.21-5.61-5.85c-2.33-1.77-4.96-3.15-7.81-4.05l-6.86,6.86l-6.59,6.59l-5.45,5.45
l-3.83-3.83l-5.76-5.76c-1.71,2.32-3.04,4.92-3.9,7.74l6.91,6.91l0.76,0.76l5.93,5.93l5.82-5.82l5.45-5.45l9.6-9.6
c2.27,1.51,4.17,3.54,5.55,5.91c1.52,2.62,2.41,5.65,2.41,8.9c0,3.51-1.04,6.78-2.8,9.54C71.47,55.61,69.61,57.47,67.41,58.87z'
                fill='#ffffff'
              />
            </g>
          </svg>
        </Link>
      </div>
      <div className='App'>
        {isPageLayoutRoute ? (
          <PageLayout onClose={handleCloseButtonClick} />
        ) : (
          <>
            <MiddleLayer
              animate={state.animateMiddleLayer}
              sphereAnimationState={state.sphereAnimationState}
              techIconAnimation={state.techIconAnimation}
              techIcons={state.currentTechIcons}
              lightColor={state.lightColor}
            />
            <ForegroundLayer
              isContentLoaded={isContentLoaded}
              onNavigate={() =>
                dispatch({
                  type: actionTypes.TOGGLE_BACK_LAYER,
                  payload: true,
                })
              }
              dispatch={dispatch}
              blockPointerEvents={state.showBackLayer} // Add this line
            />
          </>
        )}
      </div>
    </>
  );
}

export default App;
