import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import routesConfig from "./data/routesConfig.json";

const RouteContext = createContext();

export const RouteProvider = ({ children }) => {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState({});

  useEffect(() => {
    const route = routesConfig.find((r) => r.path === location.pathname) || {};
    setCurrentRoute(route);
  }, [location]);

  return (
    <RouteContext.Provider value={{ currentRoute }}>
      {children}
    </RouteContext.Provider>
  );
};

export const useRoute = () => useContext(RouteContext);
