import React, {
  useEffect,
  useState,
  useMemo,
  useLayoutEffect,
  useRef,
} from "react";
import SphereTextured from "./SphereTextured";
import { gsap } from "gsap";
import { Text } from "@react-three/drei"; // Import Text from drei

const SphereGrid = ({
  isVisible,
  techIconAnimation,
  techIcons,
  lightColor,
  techToolsOffset,
  horizOffset = 1,
}) => {
  const [groupRefs, setGroupRefs] = useState([]);
  const TOTAL_ICONS = 18;
  const objectSize = 0.3;
  const textRef = useRef();

  useEffect(() => {
    setGroupRefs(
      Array(TOTAL_ICONS)
        .fill()
        .map((_, i) => React.createRef())
    );
  }, []);

  useLayoutEffect(() => {
    groupRefs.forEach((ref) => {
      if (ref.current) {
        gsap.set(ref.current.scale, { x: 0.25, y: 0.25, z: 0.25 });
        gsap.set(ref.current.position, { x: 2, y: 0 });
      }
    });
  }, [groupRefs]);
  useEffect(() => {
    const spacing = objectSize / 1.65;
    const startX = -0.5;
    const textStartX = 5;
    const startY = -3; // Starting below the screen for spheres
    const endY = 0; // Final position on the screen for spheres
    const margin = -1; // Margin between the last sphere and the text

    const positions = techIcons.map((_, i) => {
      return [startX + i * spacing, endY, 1];
    });

    // Calculate the total width of the spheres plus their spacing
    const totalWidth =
      techIcons.length * objectSize * spacing * techToolsOffset;

    // Calculate the x position for the text by adding the total width to startX and subtracting the margin
    // Note: If there are no spheres, the text position should default to startX minus the margin
    const textXPosition =
      techIcons.length > 0 ? startX + totalWidth - spacing : 2;

    // Animate the text element to its calculated position
    if (textRef.current) {
      gsap.to(textRef.current.position, {
        x: textXPosition,
        duration: 0.5,
        ease: "expo.inOut",
      });
    }

    // Animate spheres
    groupRefs.forEach((ref, index) => {
      if (ref.current) {
        const newPosition = techIcons.includes(index)
          ? positions[techIcons.indexOf(index)]
          : [2, startY, 0]; // Use startY for spheres not in techIcons

        if (techIconAnimation.action === "animateIn") {
          gsap.fromTo(
            ref.current.position,
            { x: newPosition[0], y: startY, z: newPosition[2] },
            {
              y: newPosition[1],
              duration: 1,
              ease: "back.out(1)",
              delay: index * 0.025,
            }
          );
        } else if (techIconAnimation.action === "animateOut") {
          gsap.to(ref.current.position, {
            y: startY,
            duration: 0.5,
            ease: "back.in(1)",
            delay: index * 0.025,
          });
        }
      }
    });
  }, [techIcons, techIconAnimation, groupRefs, textRef]);
  return (
    <>
      <group position={[horizOffset, 0, 0]} scale={[2, 2, 2]}>
        <Text
          position={[3, 0.09, 0]} // Adjust position as needed
          rotation={[0, Math.PI, 0]} // Adjust rotation as needed
          fontSize={0.02}
          fontWeight='bold'
          color={lightColor} // Adjust color as needed
          ref={textRef}
        >
          TOOLS & TECH:
        </Text>
        {Array.from({ length: TOTAL_ICONS }).map((_, index) => {
          return (
            <group key={index} ref={groupRefs[index]}>
              <SphereTextured
                materialIndex={index}
                isVisible={isVisible && techIcons.includes(index)}
                objectSize={objectSize}
              />
            </group>
          );
        })}
      </group>
    </>
  );
};

export default SphereGrid;
