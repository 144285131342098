import { useState, useEffect, useRef, useLayoutEffect } from "react";

import { Canvas } from "@react-three/fiber";
import { Sphere, OrbitControls, PerspectiveCamera } from "@react-three/drei";
import SphereTextured from "./SphereTextured";
import SphereGrid from "./SphereGrid";
import SphereCylinder from "./SphereCylinder";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import routesConfig from "./data/routesConfig.json";
import * as THREE from "three";
import "./css/foreground-layer.css";

gsap.registerPlugin(ScrollTrigger);

function rgbObjectToHex(rgb) {
  const toHex = (c) => {
    // Check if the color component is undefined or null
    if (c === undefined || c === null) {
      return "00"; // Default to '00' if the component is not valid
    }
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  // Ensure rgb object exists and has the properties r, g, and b
  if (
    rgb &&
    typeof rgb === "object" &&
    "r" in rgb &&
    "g" in rgb &&
    "b" in rgb
  ) {
    return `#${toHex(rgb.r)}${toHex(rgb.g)}${toHex(rgb.b)}`;
  } else {
    // Handle the case where rgb is not a valid object
    console.error("Invalid RGB object:", rgb);
    return "#000000"; // Return black as a default/fallback color
  }
}

function DisplayThreeD({
  animate,
  onAnimationComplete,
  sphereAnimationState,
  techIconAnimation,
  techIcons,
  lightColor,
}) {
  console.log("%%%%%%%%%%%%%%%%%%% lightColor", lightColor);
  const [count, setCount] = useState(0);

  const [cameraSettings, setCameraSettings] = useState({
    position: [-4, 0, 11], // Default position
    rotation: [0, 0, 0], // Default rotation
  });

  const [animationTrigger, setAnimationTrigger] = useState(null);

  const [layerAnimationState, setLayerAnimationState] = useState("idle"); // Possible values: "idle", "open", "close"

  const [isTablet, setIsTablet] = useState(false); // 768px as the mobile breakpoint
  const [isMobile, setIsMobile] = useState(false); // 768px as the mobile breakpoint

  const [textColor, setTextColor] = useState("white");
  const [scaleFactor, setScaleFactor] = useState([1, 1, 1]);
  const [techToolsOffset, setTechToolsOffset] = useState(2.2);
  const [horizOffset, setHorizOffset] = useState(1);

  const cameraRef = useRef();
  const sphereCylinderRef = useRef();

  const foregroundLightRef1 = useRef();
  const foregroundLightRef2 = useRef();
  const foregroundLightRef3 = useRef();
  const ambientLightRef = useRef();
  const directionalLightRef = useRef();

  // Aggregate all techIcons into a single array
  const allTechIcons = routesConfig.reduce((acc, route) => {
    acc.push(...route.techIcons);
    return acc;
  }, []);

  // Remove duplicates if necessary
  const uniqueTechIcons = [...new Set(allTechIcons)];

  const handleAnimationComplete = () => {
    console.log("Animation completed!");
    // Additional logic here
  };
  useEffect(() => {
    console.log("S@!#$!@#$@#$@#$@#$@#$234@#$@#$@#$@#$@# UPDATE ", lightColor);
    setTextColor(lightColor);
  }, [lightColor]);

  useEffect(() => {
    const checkIsTablet = () => window.innerWidth < 1400;
    const tabletStatus = checkIsTablet();
    setIsTablet(tabletStatus);

    const checkIsMobile = () => window.innerWidth < 1100;
    const mobileStatus = checkIsMobile();
    setIsMobile(mobileStatus);

    console.log("Tablet STATUS: ", tabletStatus);
    console.log("Mobile STATUS: ", mobileStatus);

    if (tabletStatus) {
      setScaleFactor([0.65, 0.65, 0.65]);
      setTechToolsOffset(2.6);
      setHorizOffset(1.25);
    } else {
      setScaleFactor([1, 1, 1]);
      setTechToolsOffset(2.2);
      setHorizOffset(1);
    }

    const handleResize = () => {
      const isNowTablet = checkIsTablet();
      const isNowMobile = checkIsMobile();
      setIsTablet(isNowTablet);
      setIsMobile(isNowMobile);
      console.log("Tablet STATUS: ", isNowTablet);
      console.log("Mobile STATUS: ", isNowMobile);
      if (isNowTablet) {
        setScaleFactor([0.65, 0.65, 0.65]);
        setTechToolsOffset(2.6);
        setHorizOffset(1.25);
      } else {
        setScaleFactor([1, 1, 1]);
        setTechToolsOffset(2.2);
        setHorizOffset(1);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    console.log("LIGHT COLOR: ", lightColor);

    if (
      foregroundLightRef1.current &&
      foregroundLightRef2.current &&
      foregroundLightRef3.current &&
      ambientLightRef.current
    ) {
      gsap.to(
        [
          foregroundLightRef1.current.color,
          foregroundLightRef2.current.color,
          foregroundLightRef3.current.color,
          ambientLightRef.current.color,
          directionalLightRef.current.color,
        ],
        {
          r: 100,
          g: 100,
          b: 100,
          duration: 1,
          ease: "none",
        }
      );
    }
  }, [lightColor]);

  useEffect(() => {
    if (cameraRef.current) {
      if (sphereAnimationState === "open") {
        gsap.to(cameraRef.current.position, {
          x: -8,
          y: 2,
          z: 30,
          duration: 0.5,
          ease: "sine.inOut",
        });
        gsap.to(cameraRef.current.rotation, {
          y: -1,
          duration: 0.5,
          ease: "sine.inOut",
        });
      } else {
        gsap.to(cameraRef.current.position, {
          x: -4,
          y: 0,
          z: 11,

          duration: 0.5,
          ease: "sine.inOut",
        });
        gsap.to(cameraRef.current.rotation, {
          y: 0,
          duration: 0.5,
          ease: "sine.inOut",
        });
      }
    }
  }, [sphereAnimationState]);

  return (
    <>
      <div className='canvas-wrapper'>
        <Canvas
          className='r3f-canvas'
          style={{ pointerEvents: "none" }}

          // shadows
        >
          <fog attach='fog' args={["#000", 10, 26]} />
          <PerspectiveCamera
            makeDefault
            position={cameraSettings.position}
            rotation={cameraSettings.rotation}
            fov={55}
            ref={cameraRef}
          />
          <pointLight
            position={[-12, 2, 7]}
            intensity={0.5}
            decay={2}
            distance={7}
            ref={foregroundLightRef2}
            color='black'
          />

          <pointLight
            position={[-0.5, 0, 0]}
            intensity={0.025}
            decay={2}
            distance={20}
            ref={foregroundLightRef1}
            color='black'
          />

          <pointLight
            position={[0, 0, 8]}
            intensity={6}
            decay={3}
            distance={4}
          />

          <pointLight
            position={[-5, -1, 7]}
            intensity={5}
            decay={2}
            distance={3}
          />

          <pointLight
            position={[-3, 0, 10]}
            intensity={3}
            decay={5}
            distance={15}
            ref={foregroundLightRef3}
            color='black'
            // castShadow
          />

          <directionalLight
            position={[0, 0, -20]}
            intensity={0.015}
            ref={directionalLightRef}
          />
          <ambientLight intensity={0.002} color='white' ref={ambientLightRef} />

          <group
            position={[0, 0, -10]}
            rotation={[0, Math.PI * 0.42, Math.PI / 2]}
            ref={sphereCylinderRef}
          >
            <SphereCylinder
              animationState={sphereAnimationState}
              onAnimationComplete={handleAnimationComplete}
            />
          </group>

          <group
            position={[-4, 0, 8]}
            rotation={[0, Math.PI, 0]}
            scale={scaleFactor}
          >
            {!isMobile && (
              <SphereGrid
                isVisible={true}
                techIconAnimation={techIconAnimation}
                techIcons={techIcons}
                className='sphere-grid'
                lightColor={rgbObjectToHex(textColor)}
                techToolsOffset={techToolsOffset}
                horizOffset={horizOffset}
              />
            )}
          </group>
        </Canvas>
      </div>
    </>
  );
}

export default DisplayThreeD;
