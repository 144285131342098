import React, { useEffect, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { ScrollSmoother } from "gsap/ScrollSmoother";
import { useNavigate } from "react-router-dom";
import routesConfig from "./data/routesConfig.json";
import "./css/foreground-layer.css";

function colorToRGB(colorInput) {
  // Create a temporary element to apply the color to
  const tempElement = document.createElement("div");
  tempElement.style.color = colorInput;
  document.body.appendChild(tempElement);

  // Get the computed color style
  const style = getComputedStyle(tempElement);
  let color = style.color;
  document.body.removeChild(tempElement);

  // Convert computed color (rgb or rgba) to RGB object
  const match = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*\d+)?\)$/);
  if (match) {
    return {
      r: parseInt(match[1], 10),
      g: parseInt(match[2], 10),
      b: parseInt(match[3], 10),
    };
  }

  // If input was not a recognized CSS color name or an error occurred, return black or a default
  return { r: 1, g: 1, b: 1 };
}

const ForegroundLayer = ({ onNavigate, dispatch, isContentLoaded }) => {
  const navigate = useNavigate();

  const handleRouteChange = (path) => {
    navigate(path);
    if (onNavigate) {
      onNavigate();
    }
  };
  // useLayoutEffect(() => {
  //   ScrollSmoother.create({
  //     smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
  //   });
  // }, []);

  useEffect(() => {
    console.log("!@$)!@#$)!@#$)!@#)!@#)!@#)!@#)!@#)!@#)");

    setTimeout(() => {
      dispatch({
        type: "SET_LIGHT_COLOR",
        payload: { r: 1, g: 1, b: 1 }, // Set lightColor to black
      });
    }, 100);
  }, [dispatch]);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Ensure the scroller element exists
    const scroller = document.getElementById("smooth-content");
    if (!scroller) {
      console.error("Scroller element not found");
      return;
    }

    routesConfig.forEach((route, index) => {
      const triggerElementId = `section-${index}`; // Adjusted to target .section-content divs

      // Directly use the element instead of the selector string
      const triggerElement = document.getElementById(triggerElementId);
      if (!triggerElement) {
        console.error(`Trigger element #${triggerElementId} not found`);
        return; // Skip to the next iteration if the element is not found
      }
      dispatch({
        type: "SET_LIGHT_COLOR",
        payload: colorToRGB(routesConfig[0].colorPalette[0]), // Example target color
      });
      ScrollTrigger.create({
        trigger: triggerElement,
        start: "top-=300px center",
        end: "bottom+=300px center",

        // markers: true,
        onEnter: () => {
          dispatch({
            type: "SET_SPHERE_ANIMATION_STATE",
            payload: `palette-${index}`,
          });
          // Dispatch for tech icon spheres
          dispatch({
            type: "SET_TECH_ICON_ANIMATION",
            payload: { section: index, action: "animateIn" },
          });
          dispatch({
            type: "SET_CURRENT_TECH_ICONS",
            payload: routesConfig[index].techIcons,
          });
          dispatch({
            type: "SET_LIGHT_COLOR",
            payload: colorToRGB(routesConfig[index].colorPalette[0]), // Example target color
          });
          console.log(
            "colorToRGB(routesConfig[index].colorPalette[0])",
            colorToRGB(routesConfig[index].colorPalette[0])
          );
        },

        onLeaveBack: () => {
          console.log("onLeaveBack");
          dispatch({
            type: "SET_SPHERE_ANIMATION_STATE",
            payload: "default",
          });
          // Dispatch for tech icon spheres
          dispatch({
            type: "SET_TECH_ICON_ANIMATION",
            payload: { action: "animateOut" },
          });
          dispatch({
            type: "SET_CURRENT_TECH_ICONS",
            payload: [],
          });
          // dispatch({
          //   type: "SET_LIGHT_COLOR",
          //   payload: { r: 255, g: 255, b: 255 }, // Example target color
          // });
        },
        onLeave: () => {
          console.log("onLeave");
          dispatch({
            type: "SET_TECH_ICON_ANIMATION",
            payload: { action: "animateOut" },
          });
          dispatch({
            type: "SET_SPHERE_ANIMATION_STATE",
            payload: "default",
          });
          // Dispatch for tech icon spheres

          dispatch({
            type: "SET_CURRENT_TECH_ICONS",
            payload: [],
          });
          // dispatch({
          //   type: "SET_LIGHT_COLOR",
          //   payload: { r: 255, g: 255, b: 255 }, // Example target color
          // });
        },
        onEnterBack: () => {
          dispatch({
            type: "SET_SPHERE_ANIMATION_STATE",
            payload: `palette-${index}`,
          });
          // Dispatch for tech icon spheres
          dispatch({
            type: "SET_TECH_ICON_ANIMATION",
            payload: { section: index, action: "animateIn" },
          });
          dispatch({
            type: "SET_CURRENT_TECH_ICONS",
            payload: routesConfig[index].techIcons,
          });
          dispatch({
            type: "SET_LIGHT_COLOR",
            payload: colorToRGB(routesConfig[index].colorPalette[0]), // Example target color
          });
        },
      });
    });

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 100); // Adjust delay as needed

    return () => ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  }, [dispatch]);

  return (
    <div className='foreground-layer'>
      <div id='smooth-wrapper' className='content-container'>
        <div className='content' id='smooth-content'>
          {/* <section className='page-section'>
            <div className='section-content'>
              <div className='title-wrapper font-white clickable-title'>
                Christopher Miller
              </div>
              <div className='sub-title-wrapper font-white'>Portfolio</div>
            </div>
          </section> */}
          {routesConfig.map((route, index) => (
            <section key={route.name} className='page-section'>
              <div className='section-content' id={`section-${index}`}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {/* Arrow div */}
                  {route.isLink && (
                    <div className='nav-arrow' style={{ alignSelf: "start" }}>
                      →
                    </div>
                  )}

                  {/* Container for title and sub-title */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "10px", // Adjust spacing between arrow and text as needed
                    }}
                  >
                    {/* Title div */}
                    <div
                      className={`title-wrapper font-white ${
                        route.isLink ? "clickable-title" : ""
                      }`}
                      {...(route.isLink
                        ? { onClick: () => handleRouteChange(route.path) }
                        : {})}
                      id={`content-${index}`}
                    >
                      {route.title}
                    </div>

                    {/* Sub-title div */}
                    <div
                      className='sub-title-wrapper font-white'
                      style={{ marginTop: "5px" }} // Adjust spacing between title and sub-title as needed
                    >
                      {route.content}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ForegroundLayer;
