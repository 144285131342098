import React, { useRef, useEffect, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Sphere } from "@react-three/drei";
import * as THREE from "three";
import gsap from "gsap";
import routesConfig from "./data/routesConfig.json";

const SphereCylinder = ({
  animationTrigger,
  setAnimationTrigger,
  animationState,
  onAnimationComplete,
}) => {
  const [radialSegments, setRadialSegments] = useState(14); // Spheres around the cylinder
  const [spheresVisible, setSpheresVisible] = useState(false);

  const heightSegments = 10; // Spheres along the cylinder's height
  const objectSize = 1.4; // Sphere size
  const cylinderRadius = useRef(0); // Example ref to store the cylinder radius or any other positioning logic
  const cylinderRef = useRef();
  const animationStep = useRef(0); // Renamed from scrollOffset to animationStep

  // Create refs for each sphere in the matrix
  const sphereRefs = useRef(
    [...Array(heightSegments)].map(() =>
      Array(radialSegments)
        .fill()
        .map(() => React.createRef())
    )
  );

  const [cameraSettings, setCameraSettings] = useState({
    position: [0, 0, 5], // Default position
    rotation: [0, 0, 0], // Default rotation
  });

  const applyPalette = (colorPalette, random) => {
    sphereRefs.current.forEach((row, rowIndex) => {
      row.forEach((sphereRef) => {
        if (sphereRef.current) {
          let color;
          if (random) {
            color =
              colorPalette[Math.floor(Math.random() * colorPalette.length)];
          } else {
            color = colorPalette[rowIndex % colorPalette.length]; // Cycle through the palette colors
          }
          sphereRef.current.userData.targetColor = new THREE.Color(color);
        }
      });
    });
  };

  const lightUpColumn = (columnIndexToLightUp) => {
    sphereRefs.current.forEach((row) => {
      row.forEach((sphereRef, colIndex) => {
        const targetColor =
          colIndex === columnIndexToLightUp
            ? new THREE.Color("yellow")
            : new THREE.Color("#444444");
        if (sphereRef.current) {
          sphereRef.current.userData.targetColor = targetColor;
        }
      });
    });
  };

  const lightUpRow = (rowIndexToLightUp) => {
    sphereRefs.current.forEach((row, rowIndex) => {
      row.forEach((sphereRef) => {
        const targetColor =
          rowIndex === rowIndexToLightUp
            ? new THREE.Color("yellow")
            : new THREE.Color("#444444");
        if (sphereRef.current) {
          sphereRef.current.userData.targetColor = targetColor;
        }
      });
    });
  };
  const letterPatterns = {
    space: [
      [0, 1, 1, 1, 0],
      [1, 0, 0, 0, 1],
      [1, 1, 1, 1, 1],
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
    ],
    A: [
      [0, 1, 1, 1, 0],
      [1, 0, 0, 0, 1],
      [1, 1, 1, 1, 1],
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
    ],
    B: [
      [1, 1, 1, 1, 0],
      [1, 0, 0, 0, 1],
      [1, 1, 1, 1, 0],
      [1, 0, 0, 0, 1],
      [1, 1, 1, 1, 0],
    ],
    C: [
      [0, 1, 1, 1, 1],
      [1, 0, 0, 0, 0],
      [1, 0, 0, 0, 0],
      [1, 0, 0, 0, 0],
      [0, 1, 1, 1, 1],
    ],
    D: [
      [1, 1, 1, 1, 0],
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
      [0, 1, 1, 1, 0],
    ],
    E: [
      [1, 1, 1, 1, 1],
      [1, 0, 0, 0, 0],
      [1, 1, 1, 0, 0],
      [1, 0, 0, 0, 0],
      [1, 1, 1, 1, 1],
    ],
    F: [
      [1, 1, 1, 1, 1],
      [1, 0, 0, 0, 0],
      [1, 1, 1, 0, 0],
      [1, 0, 0, 0, 0],
      [1, 0, 0, 0, 0],
    ],
    G: [
      [0, 1, 1, 1, 1],
      [1, 0, 0, 0, 0],
      [1, 0, 1, 1, 1],
      [1, 0, 0, 0, 1],
      [0, 1, 1, 1, 1],
    ],
    H: [
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
      [1, 1, 1, 1, 1],
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
    ],
    I: [
      [1, 1, 1, 1, 1],
      [0, 0, 1, 0, 0],
      [0, 0, 1, 0, 0],
      [0, 0, 1, 0, 0],
      [1, 1, 1, 1, 1],
    ],
    J: [
      [0, 0, 0, 1, 1],
      [0, 0, 0, 0, 1],
      [0, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
      [0, 1, 1, 1, 0],
    ],
    K: [
      [1, 0, 0, 1, 0],
      [1, 0, 1, 0, 0],
      [1, 1, 0, 0, 0],
      [1, 0, 1, 0, 0],
      [1, 0, 0, 1, 0],
    ],
    L: [
      [1, 0, 0, 0, 0],
      [1, 0, 0, 0, 0],
      [1, 0, 0, 0, 0],
      [1, 0, 0, 0, 0],
      [1, 1, 1, 1, 1],
    ],
    M: [
      [1, 0, 0, 0, 1],
      [1, 1, 0, 1, 1],
      [1, 0, 1, 0, 1],
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
    ],
    N: [
      [1, 0, 0, 0, 1],
      [1, 1, 0, 0, 1],
      [1, 0, 1, 0, 1],
      [1, 0, 0, 1, 1],
      [1, 0, 0, 0, 1],
    ],
    O: [
      [0, 1, 1, 1, 0],
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
      [0, 1, 1, 1, 0],
    ],
    P: [
      [1, 1, 1, 1, 0],
      [1, 0, 0, 0, 1],
      [1, 1, 1, 1, 0],
      [1, 0, 0, 0, 0],
      [1, 0, 0, 0, 0],
    ],
    Q: [
      [0, 1, 1, 1, 0],
      [1, 0, 0, 0, 1],
      [1, 0, 0, 1, 1],
      [1, 0, 1, 0, 1],
      [0, 1, 1, 1, 1],
    ],
    R: [
      [1, 1, 1, 1, 0],
      [1, 0, 0, 0, 1],
      [1, 1, 1, 1, 0],
      [1, 0, 1, 0, 0],
      [1, 0, 0, 1, 0],
    ],
    S: [
      [0, 1, 1, 1, 1],
      [1, 0, 0, 0, 0],
      [0, 1, 1, 1, 0],
      [0, 0, 0, 0, 1],
      [1, 1, 1, 1, 0],
    ],
    T: [
      [1, 1, 1, 1, 1],
      [0, 0, 1, 0, 0],
      [0, 0, 1, 0, 0],
      [0, 0, 1, 0, 0],
      [0, 0, 1, 0, 0],
    ],
    U: [
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
      [0, 1, 1, 1, 0],
    ],
    V: [
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
      [0, 1, 0, 1, 0],
      [0, 0, 1, 0, 0],
    ],
    W: [
      [1, 0, 0, 0, 1],
      [1, 0, 0, 0, 1],
      [1, 0, 1, 0, 1],
      [1, 1, 0, 1, 1],
      [1, 0, 0, 0, 1],
    ],
    X: [
      [1, 0, 0, 0, 1],
      [0, 1, 0, 1, 0],
      [0, 0, 1, 0, 0],
      [0, 1, 0, 1, 0],
      [1, 0, 0, 0, 1],
    ],
    Y: [
      [1, 0, 0, 0, 1],
      [0, 1, 0, 1, 0],
      [0, 0, 1, 0, 0],
      [0, 0, 1, 0, 0],
      [0, 0, 1, 0, 0],
    ],
    Z: [
      [1, 1, 1, 1, 1],
      [0, 0, 0, 1, 0],
      [0, 0, 1, 0, 0],
      [0, 1, 0, 0, 0],
      [1, 1, 1, 1, 1],
    ],
  };

  const applyLetterPattern = (
    pattern,
    color = "white",
    orientation = "vertical"
  ) => {
    // Function to rotate the pattern matrix by 90 degrees
    const rotatePattern = (pattern) => {
      // Transpose the pattern matrix
      const transposed = pattern[0].map((col, i) =>
        pattern.map((row) => row[i])
      );
      // Reverse each row to complete the 90-degree rotation
      return transposed.map((row) => row.reverse());
    };

    // Rotate the pattern if the orientation is vertical
    const finalPattern =
      orientation === "vertical" ? rotatePattern(pattern) : pattern;

    sphereRefs.current.forEach((row, rowIndex) => {
      row.forEach((sphereRef, colIndex) => {
        const isPartOfLetter =
          finalPattern[rowIndex] && finalPattern[rowIndex][colIndex] === 1;
        const targetColor = isPartOfLetter
          ? new THREE.Color(color)
          : new THREE.Color("#222222"); // Highlight color or default
        if (sphereRef.current) {
          gsap.to(sphereRef.current.material.color, {
            r: targetColor.r,
            g: targetColor.g,
            b: targetColor.b,
            duration: 1, // Animation duration
          });
        }
      });
    });
  };

  const setSpheresToRandomColors = () => {
    sphereRefs.current.forEach((row) => {
      row.forEach((sphereRef) => {
        if (sphereRef.current) {
          // Ensure the generated color is a valid 6-digit hexadecimal code
          const randomColor =
            "#" +
            (
              "000000" + Math.floor(Math.random() * 16777215).toString(16)
            ).slice(-6);
          sphereRef.current.userData.targetColor = new THREE.Color(randomColor);
        }
      });
    });
  };
  const applyPropertiesBasedOnMatrixPosition = () => {
    const colors = ["red", "green", "blue", "orange"];
    sphereRefs.current.forEach((row, rowIndex) => {
      const color = colors[rowIndex % colors.length];
      row.forEach((sphereRef) => {
        if (sphereRef.current) {
          sphereRef.current.userData.targetColor = new THREE.Color(color);
        }
      });
    });
  };

  const displayLetterAtOffsetHorizontal = (
    pattern,
    offset,
    letterColor,
    backgroundColor
  ) => {
    sphereRefs.current.forEach((row, rowIndex) => {
      row.forEach((sphereRef, colIndex) => {
        // Calculate the column index in the pattern based on the current offset
        const patternColIndex = colIndex - offset;
        // Determine if this sphere is part of the letter
        const isPartOfLetter =
          pattern[rowIndex] && pattern[rowIndex][patternColIndex] === 1;
        // Set the sphere color based on whether it's part of the letter
        const color = isPartOfLetter ? letterColor : backgroundColor;
        if (sphereRef.current) {
          sphereRef.current.material.color.set(color);
        }
      });
    });
  };

  const rotatePattern = (pattern, degrees) => {
    let rotatedPattern = pattern;
    const rotations = (degrees / 90) % 4; // Ensure we only deal with 0, 90, 180, 270 degrees

    for (let i = 0; i < rotations; i++) {
      // Rotate clockwise by transposing and then reversing each row
      rotatedPattern = rotatedPattern[0]
        .map((val, index) => rotatedPattern.map((row) => row[index]))
        .reverse();
    }

    return rotatedPattern;
  };
  const displayLetterAtOffsetVertical = (
    pattern,
    offset,
    letterColor,
    backgroundColor
  ) => {
    sphereRefs.current.forEach((row, rowIndex) => {
      row.forEach((sphereRef, colIndex) => {
        // Calculate the row index in the pattern based on the current offset
        const patternRowIndex = rowIndex - offset;
        // Reverse the column index to mirror the pattern horizontally
        const patternColIndex = pattern[patternRowIndex]
          ? pattern[patternRowIndex].length - 1 - colIndex
          : -1;
        // Determine if this sphere is part of the letter
        const isPartOfLetter =
          pattern[patternRowIndex] &&
          pattern[patternRowIndex][patternColIndex] === 1;
        // Set the sphere color based on whether it's part of the letter
        const color = isPartOfLetter ? letterColor : backgroundColor;
        if (sphereRef.current) {
          sphereRef.current.material.color.set(color);
        }
      });
    });
  };
  const spaceWidth = 3; // Width of the space between words

  const wordToPattern = (sentence, spacing = 1, rotationDegrees = 0) => {
    let sentencePattern = [];
    const words = sentence.split(" "); // Split the sentence into words

    words.forEach((word, wordIndex) => {
      for (let char of word.toUpperCase()) {
        let letterPattern = letterPatterns[char]; // Assuming letterPatterns is defined
        if (letterPattern) {
          // Rotate each letter individually according to rotationDegrees
          const rotatedLetterPattern = rotatePattern(
            letterPattern,
            rotationDegrees
          );
          // Concatenate the rotated letter pattern to the sentence pattern
          sentencePattern = sentencePattern.concat(rotatedLetterPattern);
          // Add spacing after each letter
          for (let i = 0; i < spacing; i++) {
            sentencePattern.push(Array(rotatedLetterPattern[0].length).fill(0));
          }
        }
      }
      // Add space between words, but not after the last word
      if (wordIndex < words.length - 1) {
        for (let i = 0; i < spaceWidth; i++) {
          sentencePattern.push(Array(letterPatterns["A"][0].length).fill(0)); // Assuming all letters have the same height
        }
      }
    });

    // Optionally, remove the last spacing
    if (spacing > 0) {
      sentencePattern = sentencePattern.slice(0, -spacing);
    }
    return sentencePattern;
  };

  const scrollLetter = (
    wordPattern, // Already rotated correctly
    scrollDelay = 100,
    letterColor = "yellow",
    backgroundColor = "#222222",
    scrollDirection = "vertical", // "horizontal" or "vertical"
    startFromEdge = "start" // "start" or "end"
  ) => {
    let offset;
    let maxOffset;
    let updateOffset;

    if (scrollDirection === "horizontal") {
      offset =
        startFromEdge === "start" ? -wordPattern[0].length : radialSegments;
      maxOffset =
        startFromEdge === "start"
          ? radialSegments + wordPattern[0].length
          : -wordPattern[0].length;
      updateOffset =
        startFromEdge === "start" ? () => offset++ : () => offset--;
    } else {
      // "vertical"
      offset = startFromEdge === "start" ? -wordPattern.length : heightSegments;
      maxOffset =
        startFromEdge === "start"
          ? heightSegments + wordPattern.length
          : -wordPattern.length;
      updateOffset =
        startFromEdge === "start" ? () => offset++ : () => offset--;
    }

    const displayFunction =
      scrollDirection === "horizontal"
        ? displayLetterAtOffsetHorizontal
        : displayLetterAtOffsetVertical;

    const scrollStep = () => {
      displayFunction(wordPattern, offset, letterColor, backgroundColor);
      updateOffset(); // Update the offset based on the direction
      const continueScrolling =
        startFromEdge === "start" ? offset < maxOffset : offset >= maxOffset;
      if (continueScrolling) {
        setTimeout(scrollStep, scrollDelay);
      }
    };

    scrollStep(); // Start the scrolling process
  };

  const hexToRgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;
    return { r, g, b };
  };

  const animateToPattern = (targetPatternFunction, onComplete = () => {}) => {
    console.log("targetPatternFunction", targetPatternFunction);
    // Apply the target pattern to set the desired target colors
    targetPatternFunction();

    // Create a timeline
    const tl = gsap.timeline({ onComplete });

    sphereRefs.current.forEach((row) => {
      row.forEach((sphereRef, index) => {
        if (sphereRef.current && sphereRef.current.userData.targetColor) {
          const targetColor = sphereRef.current.userData.targetColor;
          // Add each color animation to the timeline
          tl.to(
            sphereRef.current.material.color,
            {
              r: targetColor.r,
              g: targetColor.g,
              b: targetColor.b,
              duration: 0.5,
              ease: "sine.inOut",
            },
            index * 0.025
          );
          // .to(
          //   sphereRef.current.material.emissive,
          //   {
          //     r: targetColor.r,
          //     g: targetColor.g,
          //     b: targetColor.b,
          //     duration: 0.5,
          //     ease: "sine.inOut",
          //   },
          //   index * 0.025
          // )
          // .to(
          //   sphereRef.current.material,
          //   {
          //     emissiveIntensity: 0.05, // Target intensity value
          //     duration: 0.5,
          //     ease: "sine.inOut",
          //   },
          //   index * 0.025
          // );
        }
      });
    });
  };

  const sentenceToPattern = (sentence, spacing = 2) => {
    const spacePattern = Array(spacing).fill(0); // Spacer between letters
    let sentencePattern = [];

    for (let char of sentence.toUpperCase()) {
      const letterPattern = letterPatterns[char];
      if (letterPattern) {
        // Add the letter pattern and a spacer
        sentencePattern = sentencePattern.concat(letterPattern, spacePattern);
      }
    }
    // console.log("sentencePattern", sentencePattern);

    return sentencePattern;
  };

  const handleScrollText = () => {
    // Assuming you have a function to start the scroll text animation
    const word = "design tech";
    const rotationDegrees = 270; // Rotate each letter 90 degrees if needed
    const wordPattern = wordToPattern(word, 1, rotationDegrees); // 1 is the spacing between letters
    scrollLetter(
      wordPattern,
      20, // scrollDelay
      "white", // letterColor
      "#222222", // backgroundColor
      "vertical", // scrollDirection
      "end" // startFromEdge
    );
  };

  const handleRandomColors = () => {
    // Assuming you have a function to set spheres to random colors
    animateToPattern(setSpheresToRandomColors);
  };

  const handleSetPalette = () => {
    // Assuming you have a function to apply a specific color palette
    animateToPattern(() => {
      applyPalette(5, true);
    }); // Example: Apply the 5th palette
  };
  console.log("SphereCylinder rendering", animationState);
  useEffect(() => {
    console.log("animationState palette", animationState.search("palette"));

    if (animationState.search("palette-") !== -1) {
      const paletteIndex = parseInt(animationState.split("palette-")[1]);
      animateToPattern(() =>
        applyPalette(routesConfig[paletteIndex].colorPalette, true)
      ); // Example: Apply the 4th palette
    } else if (animationState === "default-palette") {
      animateToPattern(() => applyPalette(["#222", "#ccc", "#ececec"])); // Example: Apply the 4th palette
    } else if (animationState === "open") {
      // Trigger the "open" animation
      openSpheresAnimation();
    } else if (animationState === "close") {
      // Trigger the "close" animation
      closeSpheresAnimation();
    }
    // Add more conditions for other palettes or states
  }, [animationState]);

  useEffect(() => {
    // Position your spheres here and calculate the cylinderRadius or other positioning logic
    // This is just a placeholder logic to simulate positioning
    setTimeout(() => {
      cylinderRadius.current = 6; // Simulate calculating the position
      setSpheresVisible(true); // Make spheres visible after positioning
    }, 100); // Assuming positioning takes 1 second (adjust based on your actual logic)
  }, []);

  useEffect(() => {
    const tl = gsap.timeline({}).timeScale(1);

    //   tl.to(
    //     {},
    //     {
    //       duration: 0.5,
    //       onComplete: () => {
    //         // const word =
    //         //   "A designer is someone who constructs while he thinks, someone for whom planning and making go together.";
    //         // const rotationDegrees = 270; // Rotate each letter 90 degrees if needed
    //         // const wordPattern = wordToPattern(word, 1, rotationDegrees); // 1 is the spacing between letters
    //         // scrollLetter(
    //         //   wordPattern,
    //         //   30, // scrollDelay
    //         //   "white", // letterColor
    //         //   "#222222", // backgroundColor
    //         //   "vertical", // scrollDirection
    //         //   "end" // startFromEdge
    //         // );
    //         // scrollLetter(letterPatterns.A, 100, "white", "#111111"); // Scroll the letter "A" with a 100ms delay between steps
    //       },
    //     }
    //   );
    //   //   .to(
    //   //   {},
    //   //   {
    //   //     duration: 2,
    //   //     onComplete: () => {
    //   //       const word = "DUDE";
    //   //       const rotationDegrees = 270; // Rotate each letter 90 degrees if needed
    //   //       const wordPattern = wordToPattern(word, 1, rotationDegrees); // 1 is the spacing between letters

    //   //       scrollLetter(
    //   //         wordPattern,
    //   //         20, // scrollDelay
    //   //         "red", // letterColor
    //   //         "#222222", // backgroundColor
    //   //         "vertical", // scrollDirection
    //   //         "end" // startFromEdge
    //   //       );

    //   //       // scrollLetter(letterPatterns.A, 100, "white", "#111111"); // Scroll the letter "A" with a 100ms delay between steps
    //   //     },
    //   //   }
    //   // );

    // .to(
    //   {},
    //   {
    //     duration: 2,

    //     onComplete: () => {
    //       animateToPattern(() => applyPalette(1)); // Example: Apply the 4th palette
    //     },
    //   }
    // )
    // .to(
    //   {},
    //   {
    //     duration: 2,

    //     onComplete: () => {
    //       animateToPattern(() => applyPalette(4)); // Example: Apply the 4th palette
    //     },
    //   }
    // );

    //   // .to(
    //   //   {},
    //   //   {
    //   //     duration: 0.25,
    //   //     onComplete: () => {
    //   //       animateToPattern(applyLetterAPattern);
    //   //     },
    //   //   }
    //   // );
    //   // .to(
    //   //   {},
    //   //   {
    //   //     duration: 1,
    //   //     onComplete: () => {
    //   //       animateToPattern(setSpheresToRandomColors);
    //   //     },
    //   //   }
    //   // );

    //   // .to(
    //   //   {},
    //   //   {
    //   //     duration: 1,
    //   //     onComplete: () => {
    //   //       animateToPattern(setSpheresToRandomColors);
    //   //     },
    //   //   }
    //   // );
  }, []);

  //Initial Animation Experiment
  ///
  useEffect(() => {
    const overallDuration = 2; // Desired overall duration
    const tweenDuration = 20; // Individual tween duration
    const effectiveTweenDuration = tweenDuration * 2; // Considering repeat & yoyo
    const totalAnimations = heightSegments + heightSegments * radialSegments; // Estimate
    const maxStaggerTime = overallDuration - effectiveTweenDuration;
    const stagger = maxStaggerTime / (totalAnimations - 1);

    const tl = gsap.timeline({ paused: false }).timeScale(1);

    sphereRefs.current.forEach((row, rowIndex) => {
      const group = cylinderRef.current.children[rowIndex];
      tl.to(
        group.rotation,
        {
          y: "+=" + Math.PI / 4,
          ease: "sine.inOut",
          duration: tweenDuration,
          repeat: -1,
          yoyo: true,
        },
        0
      );

      row.forEach((sphereRef, radialIndex) => {
        if (sphereRef.current) {
          // tl.to(
          //   sphereRef.current.position,
          //   {
          //     y: Math.random() * 1 + 5,
          //     ease: "sine.inOut",
          //     duration: tweenDuration,
          //     repeat: -1,
          //     yoyo: true,
          //   },
          //   rowIndex * 0.25 + radialIndex * stagger
          //   // rowIndex * 0.25 + radialIndex * stagger
          // );
          // tl.to(
          //   sphereRef.current.scale,
          //   {
          //     x: 0,
          //     y: 0,
          //     z: 0,
          //     ease: "sine.inOut",
          //     duration: tweenDuration,
          //     repeat: -1,
          //     yoyo: true,
          //   },
          //   rowIndex * 0.25 + radialIndex * stagger
          //   // rowIndex * 0.25 + radialIndex * stagger
          // );
          // tl.to(
          //   sphereRef.current.scale,
          //   {
          //     x: "+=0.25",
          //     y: "+=0.25",
          //     z: "+=0.25",
          //     ease: "sine.inOut",
          //     duration: tweenDuration,
          //   },
          //   rowIndex * 0.1 + radialIndex * stagger
          // );
        }
      });
      // tl.seek(3);
    });
  }, []);

  // useEffect(() => {
  //   if (animationTrigger === "scrollText") {
  //     handleScrollText();
  //   } else if (animationTrigger === "randomColors") {
  //     handleRandomColors();
  //   } else if (animationTrigger === "setPalette") {
  //     handleSetPalette();
  //   }

  //   // Reset trigger to allow re-triggering the same animation
  //   setAnimationTrigger(null);
  // }, [animationTrigger, setAnimationTrigger]);

  const openSpheresAnimation = () => {
    console.log("openSpheresAnimation");
    const tl = gsap.timeline({ paused: false });

    sphereRefs.current.forEach((row, rowIndex) => {
      const group = cylinderRef.current.children[rowIndex];
      tl.to(
        group.rotation,
        {
          y: Math.PI / 2,
          ease: "sine.inOut",
          duration: 1,
        },
        0
      );
      // tl.to(
      //   group.scale,
      //   {
      //     x: 1,
      //     y: 1,
      //     z: 1,
      //     ease: "sine.inOut",
      //     duration: 1,
      //   },
      //   0
      // );
      row.forEach((sphereRef, radialIndex) => {
        if (sphereRef.current) {
          // tl.to(
          //   sphereRef.current.scale,
          //   {
          //     x: 0,
          //     y: 0,
          //     z: 0,
          //     ease: "sine.inOut",
          //     duration: 1,
          //   },
          //   0
          // );
          tl.to(
            sphereRef.current.position,
            {
              x: 20,

              ease: "sine.inOut",
              duration: 1,
            },
            0
          );
        }
      });
    });
  };

  const closeSpheresAnimation = () => {
    // GSAP animation to "close" spheres
    const tl = gsap.timeline({ paused: false }).timeScale(1);
    sphereRefs.current.forEach((row, rowIndex) => {
      const group = cylinderRef.current.children[rowIndex];
      tl.to(
        group.rotation,
        {
          y: 0,
          ease: "sine.inOut",
          duration: 1,
        },
        0
      );
      row.forEach((sphereRef, radialIndex) => {
        if (sphereRef.current) {
          tl.to(
            sphereRef.current.scale,
            {
              x: 1,
              y: 1,
              z: 1,
              ease: "sine.inOut",
              duration: 1,
            },
            0
          );
          tl.to(
            sphereRef.current.position,
            {
              x: cylinderRadius,

              ease: "sine.inOut",
              duration: 1,
            },
            0
          );
        }
      });
    });
  };

  return (
    <group ref={cylinderRef} position={[0, 0, 0]}>
      {[...Array(heightSegments)].map((_, heightIndex) => (
        <group
          key={heightIndex}
          position={[0, heightIndex * (objectSize * 2), 0]}
        >
          {[...Array(radialSegments)].map((_, radialIndex) => {
            // Calculate the base angle for the current radial segment
            const baseAngle = (360 / radialSegments) * radialIndex;
            // Calculate additional rotation offset for every other height segment
            const additionalRotation =
              heightIndex % 2 === 0 ? 0 : (360 / radialSegments) * 0.5;
            // Combine base angle and additional rotation, then convert to radians
            const angle = THREE.MathUtils.degToRad(
              baseAngle + additionalRotation
            );

            return (
              <group
                key={radialIndex}
                rotation={[0, angle, 0]} // Apply combined rotation here
              >
                <Sphere
                  ref={sphereRefs.current[heightIndex][radialIndex]}
                  args={[objectSize, 24, 24]}
                  position={[cylinderRadius.current, 0, 0]}
                  visible={spheresVisible}
                >
                  <meshPhongMaterial
                    attach='material'
                    color='#000000'
                    specular={"white"}
                    shininess={2}
                    // emissive={"white"}
                    // emissiveIntensity={0.005}
                    // transparent
                    // opacity={0.25}
                  />
                </Sphere>
              </group>
            );
          })}
        </group>
      ))}
    </group>
  );
};

export default SphereCylinder;
