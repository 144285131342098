import React, { useEffect, useRef } from "react";
import { useLoader, useThree } from "@react-three/fiber";
import { Sphere } from "@react-three/drei";
import * as THREE from "three";
import gsap from "gsap";

const textureUrls = [
  "./images/icons/TechIcons_0012_c4d.png",
  "./images/icons/TechIcons_0016_Google_Apps_Script.svg.png",
  "./images/icons/TechIcons_0013_gulp.png",
  "./images/icons/TechIcons_0001_zbrush.png",
  "./images/icons/TechIcons_0002_polly.png",
  "./images/icons/TechIcons_0003_svg.png",
  "./images/icons/TechIcons_0004_react.png",
  "./images/icons/TechIcons_0005_premiere.png",
  "./images/icons/TechIcons_0006_photoshop.png",
  "./images/icons/TechIcons_0007_js.png",
  "./images/icons/TechIcons_0008_illustrator.png",
  "./images/icons/TechIcons_0009_after-effects.png",
  "./images/icons/TechIcons_0010_realflow.png",
  "./images/icons/TechIcons_0011_houdini.png",
  "./images/icons/TechIcons_0014_gsap.png",
  "./images/icons/TechIcons_0015_googlesheets.png",
  "./images/icons/TechIcons_0016_Google_Apps_Script.svg.png",
  "./images/icons/TechIcons_Flash.png",
];

const SphereTextured = ({
  materialIndex,
  objectSize = 1,
  position,
  isVisible,
  materialType, // Prop to determine the material type
}) => {
  const { scene } = useThree();
  const textures = useLoader(THREE.TextureLoader, textureUrls);
  const sphereRef = useRef();

  useEffect(() => {
    textures.forEach((texture) => {
      texture.offset.set(0.25, 0);
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(2, 1);
    });
  }, [textures]);

  useEffect(() => {
    if (sphereRef.current) {
      const texture = textures[materialIndex % textures.length];
      sphereRef.current.material.map = texture;
      sphereRef.current.material.needsUpdate = true;
    }
  }, [materialIndex, textures]);

  useEffect(() => {
    if (sphereRef.current) {
      gsap.to(sphereRef.current.rotation, {
        y: Math.PI * 2,
        duration: 5 + Math.random(),
        repeat: -1,
        ease: "none",
      });
    }
  }, []);

  useEffect(() => {
    if (sphereRef.current) {
      let material;
      switch (materialType) {
        case "standard":
          material = new THREE.MeshStandardMaterial({
            map: textures[materialIndex % textures.length],
          });
          break;
        case "phong":
        default:
          material = new THREE.MeshPhongMaterial({
            map: textures[materialIndex % textures.length],
            shininess: 1,
          });
      }
      sphereRef.current.material = material;
    }
  }, [materialType, materialIndex, textures]);

  return (
    <group>
      <pointLight
        position={[-0.75, 1.5, 1]}
        intensity={10}
        decay={2}
        distance={1.25}
      />
      <Sphere
        ref={sphereRef}
        args={[objectSize, 32, 32]}
        position={position}
        // scale={isVisible ? [1, 1, 1] : [0, 0, 0]}
        rotation={[0, 0, 0]}
      />
    </group>
  );
};

export default SphereTextured;
