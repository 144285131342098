import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { HashRouter } from "react-router-dom"; // Import HashRouter
import App from "./App";
import { RouteProvider } from "./RouteContext";
import "./App.css";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <HashRouter>
    <RouteProvider>
      <Suspense fallback={<div className='loader'></div>}>
        <App />
      </Suspense>
    </RouteProvider>
  </HashRouter>
);
