import React, { useRef, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { gsap } from "gsap";
import DisplayThreeD from "./DisplayThreeD";

const Box = React.forwardRef((props, ref) => (
  <mesh {...props} ref={ref}>
    <boxGeometry args={[2, 2, 2]} />
    <meshStandardMaterial color={"orange"} />
  </mesh>
));

const MiddleLayer = ({
  animate,
  onAnimationComplete,
  sphereAnimationState,
  techIconAnimation,
  techIcons,
  lightColor,
}) => {
  return (
    <div className='middle-layer'>
      <DisplayThreeD
        animate={animate}
        onAnimationComplete={onAnimationComplete}
        sphereAnimationState={sphereAnimationState} // Pass the sphereAnimationState prop to DisplayThreeD
        techIconAnimation={techIconAnimation}
        techIcons={techIcons}
        lightColor={lightColor} // Pass the lightColor prop to DisplayThreeD
      />
    </div>
  );
};

export default MiddleLayer;
