import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom"; // Removed BrowserRouter import
import routesConfig from "./data/routesConfig.json";
import "./css/page-layout.css";

const routeComponents = routesConfig.reduce((acc, route) => {
  acc[route.component] = React.lazy(() => import(`./pages/${route.component}`));
  return acc;
}, {});

function PageLayout({ onClose }) {
  // Removed totalPageCount if not used, can be added back if necessary

  const navigate = useNavigate();
  const location = useLocation();
  const currentPageIndex = routesConfig.findIndex(
    (route) => route.path === location.pathname
  );
  const nextPage = routesConfig[currentPageIndex + 1]
    ? routesConfig[currentPageIndex + 1].path
    : null;
  const prevPage =
    currentPageIndex > 1 ? routesConfig[currentPageIndex - 1].path : null;

  const handleNext = () => {
    if (nextPage) navigate(nextPage);
  };

  const handleBack = () => {
    if (prevPage) navigate(prevPage);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <div className='top-gradient'></div>

      <div className='bottom-gradient'></div>
      <div className='back-layer'>
        <button onClick={onClose} className='close-button'>
          ×
        </button>

        <div className='pageLayout-wrapper'>
          <Routes>
            {routesConfig.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={React.createElement(routeComponents[route.component])}
              />
            ))}
          </Routes>
        </div>
        <div className='navigation-buttons'>
          <button
            onClick={handleBack}
            className={`back-button ${!prevPage ? "disabled" : ""}`}
            disabled={!prevPage}
          >
            ←
          </button>

          <button
            onClick={handleNext}
            className={`next-button ${!nextPage ? "disabled" : ""}`}
            disabled={!nextPage}
          >
            →
          </button>
        </div>
      </div>
    </>
  );
}

export default PageLayout;
